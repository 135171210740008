
import CloudFun, {
  computed,
  Condition,
  defineComponent,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import { watch } from "vue-demi";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const currentCity = ref("");
    const districts = ref<any[]>([]);
    const addressDistrict = ref<any>({});
    const cityDistrict = reactive({
      city: model
        ? Object.values(model.enums.CityDistrict).map(el => {
            return { label: el.name, value: el.name, districts: el.districts };
          })
        : [],
      districts: []
    });
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      stripe: false,
      title: "經銷商",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "經銷商名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "filter-name" }
        },
        {
          field: "Address.City",
          title: "縣市",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Phone",
          title: "電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Latitude",
          title: "經度",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Longitude",
          title: "緯度",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              return model.dispatch("distributor/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("distributor/query") : undefined,
        save: model
          ? params => model.dispatch("distributor/save", params)
          : undefined
      },
      modalConfig: { width: "80%", showFooter: true }
    };

    const cityChange = (e: any) => {
      currentCity.value = e.value;
      grid.value.editingRow.Address.District = null;
      cityDistrict.districts = [];
      if (currentCity.value) {
        const item = cityDistrict.city.find(
          el => el.label === currentCity.value
        );
        if (item) {
          cityDistrict.districts = item.districts.map((el: any) => {
            return {
              label: `${el.zip} ${el.name}`,
              value: `${el.zip} ${el.name}`
            };
          });
        }
      }

      addressDistrict.value.refreshOption();
      grid.value.editingRow.Address.District = (cityDistrict
        .districts[0] as any).value;
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "經銷商名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Address.City",
          title: "縣市",
          span: 12,
          slots: { default: "columns-address-city" }
        },
        {
          field: "Address.Line",
          title: "地址",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Phone",
          title: "聯絡電話",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Uri",
          title: "網址",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Latitude",
          title: "經度",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入經度 ex: 120.3022", clearable: true },
            attrs: { type: "float" }
          }
        },
        {
          field: "Longitude",
          title: "緯度",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入緯度 ex: 22.6404", clearable: true },
            attrs: { type: "float" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
        // Address:{
        //   City: [{ type: "string", required: true }],
        //   District:[{ type: "string", required: true }],
        //   Line:[{ type: "string", required: true }]
        // }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      cityDistrict,
      currentCity,
      cityChange,
      districts,
      addressDistrict
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    initData(row: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.Address = { City: "", District: "", Line: "" };
      row.Name = "";
    }
  }
});
